@import "../../../../scss/variables";

.mobile-header {
  // height: 133px;
  height: 60px;
  &-wrapper {
    flex-direction: column;
    left: 0;
    position: fixed;
    right: 0;
    top: 0;
    z-index: 102;
    box-shadow: 0 1px 14px 0 rgba(40, 40, 40, 0.5);
    // outline: 2px solid $secondary-color;
    // border-bottom: 2px solid $primary-color;
  }

  &-top {
    height: 10px;
    background-color: $top-header;
    color: $pggroup-blu;
    align-items: center;

    span {
      font-family: Montserrat-Bold;
      letter-spacing: 0.39px;
      text-transform: uppercase;
      font-size: 10px;
      @media screen and (max-width: "576px") {
        font-size: 8px;
      }
    }

    .pin {
      svg {
        width: 12px;
        height: 12px;
        margin-right: 7px;
        cursor: pointer;
        path {
          fill: $pggroup-blu;
        }
      }
      &-link {
        cursor: pointer;
        font-family: Montserrat-SemiBold;
        font-size: 10px;
        line-height: 1.83;
        letter-spacing: 0.28px;
        color: $pggroup-blu;
      }
    }
    .shop {
      svg {
        margin-right: 7px;
        path {
          fill: $pggroup-blu;
        }
      }
      &-link {
        color: $pggroup-blu;
        text-decoration: none;
        // &:hover {
        //     text-decoration: unset;
        // }
      }
    }
  }

  &-bottom {
    padding-right: 6px;
    height: 65px;
    background-color: #ffffff;
    border-bottom: 1px solid #edeaea;
    align-items: center;
    .logo {
      width: 100px;
      svg {
        width: 170px;
      }
      img {
        max-width: 100%;
      }
    }
    .icons {
      display: flex;
      justify-content: flex-end;
      align-items: center;
    }
    .header-item {
      svg {
        width: 20px;
        height: 20px;
      }
    }
    .user {
      &-logged {
        cursor: pointer;
        border: solid 1px #3c3c3b;
        border-radius: 50%;
        font-family: Montserrat-SemiBold;
        font-size: 9px;
        letter-spacing: -0.51px;
        width: 22px;
        height: 22px;
        color: #333333;
        display: flex;
        justify-content: center;
        align-items: center;
      }
    }
  }

  &-search {
    display: flex;
    height: 40px;
    margin: 10px 10px 3px 10px;
    form{
        background-color: white;
    }
    .burger {
      display: flex;
      justify-content: center;
      align-items: center;
    }
    input {
      width: 100%;
      border: none;

      &::placeholder {
        letter-spacing: 0.12px;
        font-size: 14px;
        color: $top-header-search;
      }
    }

    .mobile-button-submit {
      background-color: unset;
      border: none;
      outline: none;

      svg {
        width: 22px;
        height: 22px;

        path {
          fill: $pggroup-blu;
        }
      }
    }
    .mobile-button-cancel {
      background-color: unset;
      border: none;
      outline: none;

      svg {
        width: 18px;
        height: 18px;

        path {
          fill: $pggroup-blu;
        }
      }
    }
  }

  .dropdown-button {
    height: 45px;
    border-radius: 2px;
    border: solid 1px #d8d8d8;
    width: 100%;
    text-align: left;
    padding-left: 15px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    font-family: Montserrat-Medium;
    font-size: 14px;
    letter-spacing: 0.6px;
    color: #333333;
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
    outline: none;
    cursor: pointer;
    position: relative;
    -webkit-transition: all 0.3s linear;
    transition: all 0.3s linear;
    // background-color: #ffffff;

    &-link {
      color: $primary-color;

      &:hover {
        text-decoration: underline;
      }
    }
  }

  .dropdown-toggle::after {
    font-size: 22px;
  }
  .dropdown--isOpen {
    background: #ffffff;
    overflow: auto;
    max-height: unset !important;
    height: auto;
  }

  .dropdown-items {
    position: absolute;
    margin: 0 10px;
    left: 0px;
    right: 0;
    top: 98%;
    border-radius: 0 0 30px 30px;
    -webkit-transition: all 0.3s linear, max-height 0.6s ease-out;
    transition: all 0.3s linear, max-height 0.6s ease-out;
    background-color: rgb(224, 227, 228);
    overflow: visible !important;
  }

  .tips-items {
    background-color: #ffffff;
    padding-top: 15px;
    margin-top: -10px;
  }

  .category-items {
    background-color: rgb(224, 227, 228);
    border-radius: 0 0 30px 30px;
    padding-top: 10px;
  }

  .dropdown-items-category {
    position: absolute;
    margin: 170px 15px 15px 15px;
    left: 0;
    right: 0;
    border-radius: 0 0 30px 30px;
    -webkit-transition: all 0.3s linear, max-height 0.6s ease-out;
    transition: all 0.3s linear, max-height 0.6s ease-out;
    // background-color:lightblue;
    background-color: rgb(224, 227, 228);
    overflow-x: hidden;
  }

  .dropdown-item {
    font-family: Montserrat-Medium;
    font-size: 12px;
    letter-spacing: 0.6px;
    //font-weight: bold;
    color: #333333;
    padding: 7px 26px;
    cursor: pointer;
  }

  .dropdown-items span:hover {
    // background-color: #ffffff;
    background-color: $pggroup-blu;
    color: white;
  }

  .dropdown-items span:active {
    background-color: $pggroup-blu;
    color: white;
  }

  .form-with-all-round-borders {
    display: flex;
    align-items: center;
    margin-top: unset;
    margin: auto;
    // width: 442px;
    width: 100%;
    height: 40px;
    border-radius: 33px;
    border: solid 1px #c0c0c0;
    padding: 0 20px;
  }

  .form-with-two-round-borders {
    display: flex;
    align-items: center;
    margin-top: unset;
    margin: auto;
    // width: 442px;
    width: 100%;
    height: 40px;
    border-radius: 33px 33px 0px 0px;
    border: solid 1px #c0c0c0;
    padding: 0 20px;
  }
}
