@import "../../../scss/variables";

.smartlist-header {
    background-color: #f7f7f7;
    .content {
        display: flex;
        justify-content: center;
    }

    select {
        height: 35px;
        width: 250px;
        margin-right: 20px;
        border: 2px solid $secondary-color!important;    
        @media screen and (max-width: 576px) {
            width: 100%;
        }
    }
}

.smartlist-header-button {
    height: 35px;
    margin-right: 20px;
    border: none;
    background-color: $secondary-color;
    color: white;
    font-family: Montserrat-Bold;
    text-transform: uppercase;
    font-size: 12px;
    &:hover {
        transition: ease-in-out;
        transition-duration: 0.2s;
        border: 2px solid $secondary-color!important;    
        background-color: transparent;
        color: $secondary-color;
    }
    @media screen and (max-width: 576px) {
        width: 100%;
    }
    
    span {
        padding: 0 15px;
        letter-spacing: 0.6px;
    }

    &--secondary {
        border: 2px solid $secondary-color!important;    
        background-color: transparent;
        color: $secondary-color;
        .text {
            display: flex;
            justify-content: space-between;
        }
        &:hover {
            transition: ease-in-out;
            transition-duration: 0.2s;
            border: none;
            background-color: $secondary-color;
            color: white;
        }
    }
}