@import "../../scss/variables";

.contactUs {
    width: 100%;
    max-width: 887px;
    @media screen and (max-width: 991px) {
        max-width: 90%;
    }
    .row {
        margin-bottom: 30px;
    }
    margin: auto;
    margin-top: 60px;
    margin-bottom: 60px;
    &-title {
        text-align: center;
        font-family: Montserrat-Bold;
        font-size: 36px;
        letter-spacing: 1.54px;
        color: #333333;
        margin-bottom: 32px;
    }
    &-subtitle {
        font-family: Montserrat-Regular;
        font-size: 16px;
        line-height: 1.44;
        letter-spacing: 0.69px;
        color: #3c3c3b;
        margin-bottom: 30px;
        .bold {
            font-family: Montserrat-Bold;
        }
        .supermercato {
            font-family: Montserrat-Bold;
            color: $secondary-color;
        }
    }
    &-select{
        background: none;
        font-size: 14px;
        padding: .4rem .75rem;
        padding-left: 2px;
        display: block;
        border: none;
        border-radius: 0;
        line-height: 1.5;
        border-bottom: 1px solid #ced4da;
        font-family: Montserrat-Medium;
        color: #212528; 
        cursor: pointer;
    }
    &-card {
        border-radius: 8px;
        box-shadow: $box-shadow;
        background-color: #ffffff;
        padding: 20px;
        &-title {
            font-family: Montserrat-Bold;
            font-size: 18px;
            letter-spacing: 0.77px;
            color: #333333;
            text-transform: uppercase;
            margin-bottom: 21px;
        }
        .radio-buttons-title {
            font-family: Montserrat-Bold;
            font-size: 12px;
            letter-spacing: 0.6px;
            color: #333333;
            margin-bottom: 13px;
        }
        .radio-buttons {
            display: flex;
            margin-bottom: 26px;
            span {
                font-family: Montserrat-Medium;
                font-size: 14px;
                letter-spacing: 0.6px;
                color: #212528;
            }
        }
        .custom-control {
            min-height: unset;
        }
        .cta {
            margin-bottom: unset;
        }
        .textarea {
            border: solid 1px #d8d8d8;
            font-family: Montserrat-Regular;
            font-size: 12px;
            letter-spacing: 0.6px;
            color: #7a7a7a;
        }
        .message-label {
            font-family: Montserrat-Regular;
            font-size: 12px;
            letter-spacing: 0.6px;
            color: #7a7a7a;
        }
    }
}