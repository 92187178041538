@import "../../scss/variables";

.signup {
    &-banner {
        background-repeat: no-repeat;
        background-position: center;
        background-size: cover;
        height: 200px;
        display: flex;
        justify-content: center;
        align-items: center;
        // margin-bottom: 52px;
        .text {
            max-width: 476px;
            @media screen and (max-width: "576px") {
                max-width: 350px;
            }
        }
        span {
            font-family: Montserrat-Bold;
            font-size: 36px;
            letter-spacing: 1.54px;
            text-align: center;
            color: #ffffff;
            @media screen and (max-width: "576px") {
                font-size: 26px;
            }
        }
    }
    &-facebook {
        text-align: center;
    }
    &-content {
        max-width: 889px;
        width: 100%;
        margin: 0 auto;
        .facebook-cta {
            margin-top: 29px;
            margin-bottom: 36px;
        }
    }
    &-title {
        font-family: Montserrat-Bold;
        font-size: 30px;
        letter-spacing: 1.29px;
        text-align: center;
        color: #333333;
        margin-bottom: 23px;
        margin-top: 20px;
    }
    
    &-card {
        border-radius: 19px;
        box-shadow: 0 0 7px 0 #c6c6c6;
        background-color: #ffffff;
        max-width: 701px;
        width: 100%;
        @media screen and (max-width: "991px") {
            width: 90%;
            padding-right: 20px;
            padding-left: 20px;
        }
        margin: 0 auto;
        padding-top: 30px;
        padding-right: 20px;
        padding-left: 24px;
        padding-bottom: 37px;
        margin-top: 38px;
        .card-title {
            font-family: Montserrat-Bold;
            font-size: 18px;
            line-height: 1.33;
            letter-spacing: 0.49px;
            color: #000000;
        }
        hr {
            height: 1px;
            background-color: $primary-color;
            margin-top: 18px;
            margin-bottom: 25px;
        }
        .form-group {
            margin-bottom: 2rem;
        }
    }
    &-actions {
        display: flex;
        justify-content: center;
        gap: 20px;
        margin-top: 32px;
        margin-bottom: 40px;
        .cta, .secondary-cta {
            width: 250px;
        }
    }
    .radio-button {
        margin-right: 30px;
        .custom-control-label {
            margin-bottom: unset;
        }
    }
    .gender {
        font-family: Montserrat-Bold;
        font-size: 14px;
        line-height: 1.71;
        letter-spacing: 0.6px;
        color: #333333;
        margin-right: 20px;
    }
    .custom-control {
        min-height: unset;
    }
    .custom-control-label {
        font-family: Montserrat-Regular;
        font-size: 14px;
        letter-spacing: 0.6px;
        color: #333333;
        padding-left: 10px;
        margin-bottom: 20px;
        &::after, &::before {
            cursor: pointer;
        }
    }
    .custom-checkbox {
        margin-bottom: 20px;
        .custom-control-label {
            margin-bottom: unset;
        }
    }
    &-select {
        background: none;
        font-size: 14px;
        padding: .375rem .75rem;
        padding-left: 2px;
        display: block;
        width: 100%;
        border: none;
        border-radius: 0;
        line-height: 1.5;
        border-bottom: 1px solid #ced4da;
        font-family: Montserrat-Medium;
        color: #212528; 
        cursor: pointer;
    }
    .select-label {
        color: #7a7a7a;
        font-family: Montserrat-Regular;
        font-size: 12px;
        margin-bottom: unset;
        letter-spacing: 0.6px;
    }
}

.separator {
    display: flex;
    align-items: center;
    text-align: center;
    margin-bottom: 42px;
    span {
        font-family: Montserrat-Regular;
        font-size: 12px;
        letter-spacing: 0.51px;
        color: #333333;
    }
}
.separator::before, .separator::after {
    content: '';
    flex: 1;
    border-bottom: 1px solid $primary-color;
}
.separator::before {
    margin-right: 16px;
}
.separator::after {
    margin-left: 16px;
}

.card-alert {
    margin-top: 8px;
    display: flex;
    align-items: center;
    font-family: Montserrat-Regular;
    background-color: #f8f8d5;
    font-size: 10px;
    padding: 4px 8px 4px 6px;
    color: #333333;
    svg {
        margin-right: 4px;
    }
}

.profile-incomplete {
    svg {
        width: 150px;
        fill: $secondary-color;
    }
}

.modal-popup {
    padding: 10px;
    margin-bottom: 15px;

    .email-success-log {
        margin-bottom: 30px;
    }

    .modal-popup-heading {
        font-family: Montserrat-Bold;
        font-size: 18px;
        letter-spacing: 0.18px;
        text-align: center;
        color: #333333;
        margin-bottom: 12px;
    }

    .modal-popup-text {
        font-family: Montserrat-Regular;
        font-size: 14px;
        line-height: 1.57;
        letter-spacing: 0.14px;
        text-align: center;
        color: #333333;
        margin-bottom: 15px;
        max-width: 320px;
    }
    .modal-popup-btn-div {
        margin-bottom: 25px;
    }

    .modal-popup-btn-text {
        font-family: Montserrat-Bold;
        font-size: 14px;
        text-transform: uppercase;
        letter-spacing: 0.1px;
        text-align: center;
        color: #ffffff;
        margin: auto;

    }
}