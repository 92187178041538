@import "../../../scss/variables";

.new-counter {
    display: flex;
    width: 110px;
    button {
        height: 35px;
        padding: 16px 10px 17px 11px;
        border-radius: 4px;
        background-color: $primary-color;
        color: $secondary-color;
        display: flex;
        justify-content: center;
        align-items: center;
        border: none;
        svg path {
            fill: $secondary-color;
        }
        &:hover {
            background-color: $secondary-color;
            color: #ffffff;
            svg path {
                fill: #ffffff;
            }
        }
        &:disabled {
            background-color: #c1c1c1;
            pointer-events: none;
        }
    }
    .minus {
        border-top-right-radius: unset;
        border-bottom-right-radius: unset;
    }
    .plus {
        border-top-left-radius: unset;
        border-bottom-left-radius: unset;
    }
    .amount {
        // padding: 8px 15px;
        width: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        // height: 35px;
        // width: 40px;
        background-color: #f9f9f9;
        font-size: 14px;
        font-family: Montserrat-Bold;
        letter-spacing: 0.42px;
        color: #092853;
        input {
            width: 100%;
            border: none;
            background-color: unset;
            text-align: center;
        }
    }
}