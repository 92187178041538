@import "../../scss/variables";

.coupons {
    padding: 30px 50px;
    @media screen and (max-width: "991px") {
        padding: 30px 20px;
    }
    &-title {
        font-family: Montserrat-Bold;
        font-size: 30px;
        letter-spacing: 1.29px;
        text-align: center;
        color: #333333;
        margin-bottom: 40px;
        @media screen and (max-width: "576px") {
            margin-bottom: 20px;
            font-size: 26px;
        }
    }
    &-content {   
        display: flex;
        justify-content: center;
        align-items: center;
        column-gap: 20px; 
        @media screen and (max-width: "1024px") {
            row-gap: 20px;
            flex-wrap: wrap;
        }
    }
    &-card {
        display: flex;
        justify-content: center;
        flex-direction: column;
        align-items: center;
        flex-basis: 25%;
        @media screen and (max-width: "1024px") {
            flex-basis: 48%;
        }
        @media screen and (max-width: "576px") {
            flex-basis: 100%;
        }
        border-radius: 8px;
        box-shadow: $box-shadow;
        background-color: #ffffff;
        .description {
            font-family: Montserrat-Bold;
            font-size: 16px;
            letter-spacing: 0.69px;
            color: #333333;
            margin-top: 10px;
            margin-bottom: 10px;
        }
        img {
            width: 100%;
        }
        .cta {
            width: 90%;
        }
    }
    .coupon-ACTIVATED {
        pointer-events: none;
        background-color: #8DCE35;
    }
    .coupon-BURNT {
        pointer-events: none;
        background-color: #C8C3C1;
    }
    &-noItems {
        font-size: 22px;
        font-family: Montserrat-Regular;
    }
}