@import '../../../scss/_variables.scss';

.profile {
    margin: 0 auto;
    margin-left: 50px;
    @media screen and (max-width: 991px) {
        margin-left: unset;
    }
    border-radius: 8px;
    padding: 20px;
    box-shadow: $box-shadow;
    background-color: #ffffff;
    &-title {
        font-family: Montserrat-Bold;
        font-size: 18px;
        letter-spacing: 0.77px;
        color: #333333;
        text-transform: uppercase;
        padding-bottom: 21px;
    }
    &-subtitle {
        font-family: Montserrat-Bold;
        font-size: 14px;
        letter-spacing: 0.6px;
        color: #333333;
        text-transform: uppercase;
    }
    textarea {
        resize: none;
        width: 100%;
        height: 288px;
        border-radius: 8px;
        border: solid 1px #d8d8d8;
        
        margin-top: 15px;
        margin-bottom: 21px;
        padding-top: 19px;
        padding-left: 15px;
        padding-bottom: 13px;
        padding-right: 18px;

        font-family: Montserrat-SemiBold;
        font-size: 12px;
        letter-spacing: 0.51px;
        color: #333333;
        &::-webkit-scrollbar {
            width: 6px;
            height: 100px;
            border-radius: 5px;
        }
        
        &::-webkit-scrollbar-track {
            border-radius: 5px;
            background-color: #f1f1f1;
        }
        
        &::-webkit-scrollbar-thumb {
            border-radius: 5px;
            background-color: $secondary-color;
        }
    }
    &-save {
        // margin-top: 30px;
        text-align: right;
    }
    .custom-control {
        min-height: unset;
        padding-bottom: 20px;
        font-family: Montserrat-Regular;
        font-size: 14px;
        line-height: 1.5;
        letter-spacing: 0.6px;
        color: #333333;
        &-label::after {
            cursor: pointer;
        }
    }
}
