.leaflet {
  overscroll-behavior: none;
  padding: 30px 50px;
  @media screen and (max-width: "991px") {
    padding: 30px 20px;
  }
  &-content {
    margin: auto;
    max-width: 50%;
    @media screen and (max-width: "991px") {
      max-width: 70%;
    }
    @media screen and (max-width: "576px") {
      max-width: 90%;
    }
  }
  .carousel-slider .control-arrow {
    opacity: unset !important;
    background: rgba(0, 0, 0, 0.2) !important;
  }
}

.leaflet-frame {
  overscroll-behavior: none;
  padding: 30px 50px;
  @media screen and (max-width: "991px") {
    padding: 30px 20px;
  }
  .go-back-button {
    margin-left: 0;
  }
  .framePdf {
    display: flex;
    align-items: center;
    iframe {
      height: calc(100vh - 145px);
      width: 100vw;
    }
  }
}
