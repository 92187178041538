$sub-color: grey;
$main-color: black;

@mixin shrinkLabel {
  top: -13px;
  font-size: 12px;
}

.group {

  .form-input {
    background: none;
    background-color: white;
    padding: .375rem .75rem;
    padding-left: 0;
    display: block;
    width: 100%;
    border: none;
    border-radius: 0;
    border-bottom: 1px solid #ced4da;
    font-family: Montserrat-Medium;
    font-size: 14px;
    letter-spacing: 0.6px;
    color: #212528;
    
    &:focus {
      outline: none;
    }

    &:focus ~ .form-input-label {
      @include shrinkLabel();
    }
  }

  .input-required {
    border-bottom: 1px solid #dd0202!important;
  }

  input[type='password'] {
    letter-spacing: 0.3em;
  }

  input {
    &:disabled {
      background-color: rgba(170, 170, 170, 0.185);
    }
  }

  .form-input-label {
    position: absolute;
    pointer-events: none;
    left: 100;
    top: .375rem;
    transition: 300ms ease all;
    font-family: Montserrat-Regular;
    font-size: 12px;
    letter-spacing: 0.6px;
    color: #7a7a7a;

    &.shrink {
      @include shrinkLabel();
    }
  }
}

.input-container {
  display: -ms-flexbox; /* IE10 */
  display: flex;
  width: 100%;
  .icon {
    text-align: center;
    border-bottom: 1px solid #ced4da;
    cursor: pointer;
    svg {
      width: 18px;
      height: auto;
    }
  }
}

