@import '../../../scss/_variables.scss';

.password-change {
    margin: 0 auto;
    margin-left: 50px;
    @media screen and (max-width: 991px) {
        margin-left: unset;
    }
    border-radius: 8px;
    padding: 20px;
    box-shadow: $box-shadow;
    background-color: #ffffff;
    &-title {
        font-family: Montserrat-Bold;
        font-size: 18px;
        letter-spacing: 0.77px;
        color: #333333;
        text-transform: uppercase;
        margin-bottom: 31px;
    }
    .text-muted {
        color: #7a7a7a;
        font-family: Montserrat-Regular;
    }
    .cta {
        margin-bottom: unset;
    }
}