@import "../../../../scss/variables";

.addAddress {
  width: 85%;
  margin: 10px 25px;
  background-color: #f4f4f4;
  text-transform: uppercase;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 35px;
  border-radius: 8px;
  border: dashed 2px $secondary-color;
  background-color: #faf3eb;
  .text {
    padding-left: 5px;
    font-family: Montserrat-Bold;
    font-size: 15px;
    letter-spacing: 0.77px;
    text-align: center;
    color: $secondary-color;
    @media screen and (max-width: 390px) {
      font-size: 14px;
    }
    @media screen and (max-width: 300px) {
      font-size: 12px;
      letter-spacing: 0px;
      padding-left: 3px;
    }
  }
}
