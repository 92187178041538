@import '../../scss/_variables.scss';

.profile-container {
    padding: 50px;
    @media screen and (max-width: "576px") {
        padding: 20px;
        padding-top: 50px
    }
}

.profile-menu {
    margin: 0 auto;
    max-width: 433px;
    width: 100%;
    border-radius: 8px;
    box-shadow: $box-shadow;
    background-color: #ffffff;
    padding-top: 21px;
    padding-left: 25px;
    padding-bottom: 6px;
    @media screen and (max-width: "991px") {
        margin-bottom: 30px;
        max-width: unset;
    }
    &-title {
        font-family: Montserrat-Regular;
        font-size: 14px;
        letter-spacing: 0.6px;
        color: #333333;
    }
    &-name {
        font-family: Montserrat-Bold;
        font-size: 24px;
        letter-spacing: 1.03px;
        color: #333333;
    }
    &-points {
        display: flex;
        align-items: center;
        height: 40px;
        width: 90%;
        .points {
            height: 40px;
            border: 1px solid #ccc;
            border-left: none;
            border-radius: 0 8px 8px 0;
            display: flex;
            padding-left: 10px;
            align-items: center;
            width: 100%;
            font-family: Montserrat-Regular;
            b {
                margin-left: 5px;
                font-family: Montserrat-Bold;
            }
        }
        .card-icon {
            padding: 5px;
            height: 100%;
            background-color: #2D314F;
            border-radius: 8px 0 0 8px;
            display: flex;
            justify-content: center;
            align-items: center;
            svg path {
                fill: #ffffff;
            }
        }
    }
    hr {
        width: 95%;
        height: 1px;
        background-color: #d8d8d8;
        margin-right: 20px;
    }
    .profile-link {
        font-family: Montserrat-Bold;
        font-size: 14px;
        letter-spacing: 0.6px;
        color: #333333;
        text-transform: uppercase;
        padding-bottom: 20px;
        &:hover {
            a {
                color: $secondary-color;
            }
        }
        &-active {
            a {
                color: $secondary-color;
            }
        }
    }
    .profile-content {
        margin: 0 auto;
        max-width: 700px;
        width: 100%;
    }
    .cta {
        margin-top: 20px;
        background: #ffffff;
        border-radius: 8px;
        border: solid 2px $secondary-color;
        margin-right: 50px;
        span {
            color: $secondary-color;
        }
        &:hover {
            background-color: $secondary-color;
            span {
                color: #ffffff;
            }
        }
    }
}

.favStore{
    margin-top: 10px;
    label{
        color: #767676;
        font-family: Montserrat-Regular;
        font-size: 14px;
        font-style: normal;
        font-weight: 300;
        line-height: normal;
    }
    select{
        border: 0;
        width: 90%;
        border-bottom: 1px solid black;
        background-color: white;
        box-shadow: 0 0 14px 0 rgba(153, 153, 153, 0.23);
        padding: 8px;
        border-radius: 8px;
    }
}