$primary-color: #D3D731;
$secondary-color: #302E49;
$access-color: #f08300;
$pggroup-blu: #2d314f;
$pggroup-green: #65b32e;
$top-header: #d3d918;
$top-header-search:#d4d4d4;
$footer-top: #D3D731;
$footer-bottom: #858808;
$card-hover: rgba(0, 0, 0, 0.14);
$box-shadow: 0 0 14px 0 rgba(153, 153, 153, 0.23);
$address-button: #f4f4f4;

