@import '../../../scss/_variables.scss';

.card-product {
    width: 205px;
    max-width: 205px;
    // height: 383px;
    border-radius: 8px;
    box-shadow: $box-shadow;
    background-color: #ffffff;
    margin: 0 10px 50px;
    @media screen and (min-width: 351px) and (max-width: 361px) {
        max-width: 160px;
        margin: 0 5px 50px;
    }
    @media screen and (min-width: 361px) and (max-width: 390px) {
        max-width: 165px;
        margin: 0 5px 50px;
    }
    @media screen and (min-width: 390px) and (max-width: 539px) {
        max-width: 170px;
        margin: 0 5px 50px;
    }
    &:hover {
        transform: scale(1.05);
        transition: all 0.3s;
        img {
            transform: scale(1.1);
            transition: all 1.4s;
        }
    }
    

    &-container {
        position: relative;
        width: 100%;
        height: 122px;
        text-align: center;
        overflow: hidden;
    }

    &-image {
        img {
            @media screen and (max-width: "576px") {
                max-height: 100px;
                max-width: 100px;
            }
            text-align: center;
            margin-top: 22px;
            max-height: 122px;
            max-width: 122px;
            z-index: 1;
        }
    }

    .out-of-stock {
        position: absolute;
        top: 25px;
        right: -30px;
        height: 25px;
        transform: rotate(45deg);
        font-family: Montserrat-Bold;
        font-size: 10px;
        line-height: 2.2;
        letter-spacing: 0.4px;
        color: #ffffff;
        
        text-transform: uppercase;
        background-color: $secondary-color;
        padding: 0 25px;
      }

    &-promo {
        position: absolute;
        z-index: 2;
        height: 26px;
        top: 14px;
        text-transform: uppercase;
        width: 115px;
        border-top-right-radius: 8px;
        border-bottom-right-radius: 8px;
        background-color: #e60c0c;
        span {
            font-family: Montserrat-Bold;
            font-size: 10px;
            line-height: 2.2;
            letter-spacing: 0.4px;
            color: #ffffff;
        }
    }
    &-details {
        margin-top: 25px;
        @media screen and (max-width: 576px) {
            margin-top: unset;
        }
        padding-right: 10px;
        padding-left: 10px;
        height: 66px;
        span {
            font-stretch: normal;
            font-style: normal;
            color: #212529;
            display: block;
            display: -webkit-box;
            text-overflow: ellipsis;
            overflow: hidden;
            word-wrap: break-word;
            -webkit-line-clamp: 3;
            -webkit-box-orient: vertical;
            font-family: Montserrat-Bold;
            font-size: 14px;
            letter-spacing: 0.6px;
            color: #333333;
            text-transform: uppercase;
        }
    }
    &-um {
        margin-top: 6px;
        padding-left: 10px;
        padding-right: 19px;
        @media screen and (max-width: 576px) {
            padding-right: 10px;
        }
        height: 13px;
        font-family: Montserrat-Regular;
        font-size: 10px;
        letter-spacing: 0.87px;
        color: #666666;
        display: flex;
        justify-content: space-between;
        align-items: center;
        .icons {
            float: right;
        }
        svg {
            width: 30px;
            @media screen and (max-width: 576px) {
                width: 25px;
            }
        }
    }
    &-prices {
        padding-left: 10px;
        padding-right: 10px;
        margin-top: 11px;
        display: flex;
        justify-content: space-between;
        align-items: center;
        .strike {
            height: 18px;
            font-family: Montserrat-Regular;
            font-size: 14px;
            letter-spacing: 1.22px;
            color: #7a7a7a;
        }
        .discount {
            font-family: Montserrat-ExtraBold;
            font-size: 14px;
            color: $secondary-color;
        }
        .netTotal {
            font-family: Montserrat-Bold;
            font-size: 14px;
            text-align: right;
            color: #333333;
        }
    }
    &-buttons {
        padding-left: 8px;
        padding-right: 8px;
        margin-top: 22px;
        margin-bottom: 15px;

        display: flex;
        justify-content: space-between;
        align-items: center;
        @media screen and (max-width: "576px") {
            // flex-direction: column;
            margin-top: 8px;
            margin-bottom: 8px;
            .new-counter {
                width: 100px;
                // margin-bottom: 5px;
            }
        }
    
        .cart {
            width: 68px;
            height: 35px;
            border-radius: 4px;
            background-color: $primary-color;
            border: none;
            color: $secondary-color;
            display: flex;
            justify-content: space-between;
            align-items: center;
            font-family: Montserrat-SemiBold;
            font-size: 14px;
            letter-spacing: 0.42px;
            @media screen and (max-width: "576px") {
                width: 45px;
                justify-content: center;
            }
            &:hover {
                background-color: $secondary-color;
                color: #ffffff;
                svg path {
                    fill: #ffffff;
                }
            }
            svg path {
                fill: $secondary-color;
            }
            svg {
                width: 13px;
                height: auto;
                // @media screen and (max-width: "576px") {
                //     margin-right: 5px;
                // }
            }
            &:disabled {
                background-color: #c1c1c1;
                pointer-events: none;
            }
        }
    }

    &-slist {
        padding-left: 8px;
        padding-right: 8px;
        padding-bottom: 10px;
        .add-to-list {
            text-transform: uppercase;
            width: 100%;
            display: flex;
            justify-content: center;
            align-items: center;
            height: 43px;
            border-radius: 8px;
            border: solid 1.5px $primary-color;
            background-color: $primary-color;
            font-size: 14px;
            font-family: Montserrat-SemiBold;
            letter-spacing: 0.42px;
            color: $secondary-color;
            &:hover {
                background-color: $secondary-color;
                border: none;
                color: #ffffff;
                svg path {
                    fill: #ffffff;
                }
            }
            svg path {
                fill: $secondary-color;
            }
            svg {
                width: 19px;
                height: auto;
                margin-right: 5px;
            }
            &:disabled {
                background-color: #c1c1c1;
                pointer-events: none;
                border: none;
                color: #ffffff;
                svg path {
                    fill: #ffffff;
                }
            }
        }
        .is-already-in-list {
            text-transform: uppercase;
            width: 100%;
            display: flex;
            justify-content: center;
            align-items: center;
            height: 43px;
            border-radius: 8px;
            font-size: 14px;
            font-family: Montserrat-SemiBold;
            letter-spacing: 0.42px;
            color: #ffffff;
            background-color: #00b8d4;
            border: none;
            pointer-events: none;
            svg {
                width: 18px;
                height: auto;
                margin-right: 8px;
            }
        }
    }
     
    &-unavailable {
        margin-top: 16px;
        padding-left: 6px;
        padding-right: 6px;
        .unavailable-content {
            border-radius: 8px;
            background-color: #f2f2f2;
            text-align: center;
            padding: 5px;
            line-height: 1.2;
            span {
                font-size: 12px;
                @media screen and (max-width: "576px") {
                    font-size: 10px;
                }
                color: #d81818;
                text-transform: uppercase;
                font-family: FuturaStd-Book;
                letter-spacing: 1.5px;
            }
        }
    }
    &-icons {
        padding-left: 13px;
        width: 30px;
        height: 30px;
    }
}