@import "../../scss/variables";

.category-select {
    display: flex;
    flex-direction: column;
}

.category-button {
    width: 161px;
    @media screen and (max-width: "1024px") {
        width: 130px;
    }
    height: 45px;
    padding: 0 11px;
    display: flex;
    justify-content: center;
    background-color: #D3D731;
    align-items: center;
    font-family: Montserrat-Bold;
    font-size: 12px;
    letter-spacing: 0.09px;
    color: #2d314f;
    text-transform: uppercase;
    cursor: pointer;
    border: none;

    display: flex;
    justify-content: space-between;
}

.category-dropdown {
    height: 258px;
    border-bottom-left-radius: 4px;
    border-bottom-right-radius: 4px;
    border: solid 1px #d8d8d8;
    border-top: none;
    background-color: #ffffff;
    position: absolute;
    top: 45px;
    width: 161px;
    padding: 15px;
    overflow-y: auto;
    @media screen and (max-width: "1024px") {
        width: 130px;
    }
    &-item {
        margin: 0 0 7px;
        font-family: Montserrat-Bold;
        font-size: 12px;
        letter-spacing: 0.44px;
        color: #333333;
        cursor: pointer;
        &:hover {
            color: $secondary-color;
        }
    }
}