@import "../../../../scss/variables";

.header {
  height: 84px;
  &-wrapper {
    flex-direction: column;
    left: 0;
    position: fixed;
    right: 0;
    top: 0;
    z-index: 102;
    background-color: #fafafa;
    max-width: 2000px;
    margin: 0 auto;
    box-shadow: 0 1px 14px 0 rgba(40, 40, 40, 0.5);
    // outline: 2px solid $secondary-color;
    // border-bottom: 2px solid $primary-color;
  }

  &-top {
    padding-left: 50px;
    padding-right: 50px;
    // height: 40px;
    height: 10px;
    background-color: $top-header;
    color: $pggroup-blu;
    align-items: center;

    span {
      font-family: Montserrat-Bold;
      font-size: 12px;
      letter-spacing: 0.1px;
      text-transform: uppercase;
      &.status {
        text-transform: lowercase;
        font-family: Montserrat-ExtraBold;
      }
    }
    .pin {
      svg {
        width: 14px;
        height: 18.4px;
        margin-right: 7px;
        path {
          fill: $pggroup-blu;
        }
      }
      &-link {
        font-size: 12px;
        line-height: 1.83;
        letter-spacing: 0.28px;
        color: $pggroup-blu;
      }
    }
    .order {
      flex-grow: 1;
      text-align: center;
      font-family: Montserrat-Regular;
      font-size: 12px;
      line-height: 1.83;
      letter-spacing: 0.48px;
      color: $pggroup-blu;
      &-link {
        text-decoration: none;
        span {
          font-size: 14px;
          font-family: Montserrat-Bold;
          letter-spacing: 0.56px;
          color: $pggroup-blu;
        }
      }
    }
    .shop {
      svg {
        margin-right: 7px;
        path {
          fill: $pggroup-blu;
        }
      }
      &-link {
        color: $pggroup-blu;
        text-decoration: none;
        // &:hover {
        //     text-decoration: unset;
        // }
      }
    }
  }

  &-bottom {
    padding-left: 50px;
    padding-right: 40px;
    height: 70px;
    background-color: #ffffff;
    align-items: center;
    .logo {
      // padding-bottom: 6px;
      width: 115px;
      img {
        max-width: 100%;
        width: auto;
      }
    }

    .search {
      display: flex;
      height: 60px;
      //   margin-top: 20px;
      //   form {
      //     display: flex;
      //     // width: 100%;
      //     margin-top: unset;
      //     margin: auto;
      //   }
      input {
        width: 100%;
        border: none;

        &::placeholder {
          letter-spacing: 0.12px;
          font-size: 14px;
          color: $top-header-search;
        }
      }
      .button-submit {
        background-color: unset;
        border: none;
        outline: none;

        svg {
          width: 27px;
          height: 27px;

          path {
            fill: $pggroup-blu;
          }
        }
      }

      .button-cancel {
        background-color: unset;
        border: none;
        outline: none;

        svg {
          width: 22px;
          height: 22px;

          path {
            fill: $pggroup-blu;
          }
        }
      }
    }
    .dropdown-button {
      height: 45px;
      border-radius: 2px;
      border: solid 1px #d8d8d8;
      width: 100%;
      text-align: left;
      padding-left: 15px;
      display: flex;
      justify-content: space-between;
      align-items: center;
      font-family: Montserrat-Medium;
      font-size: 14px;
      letter-spacing: 0.6px;
      color: #333333;
      -webkit-box-sizing: border-box;
      box-sizing: border-box;
      outline: none;
      cursor: pointer;
      position: relative;
      -webkit-transition: all 0.3s linear;
      transition: all 0.3s linear;
      // background-color: #ffffff;

      &-link {
        color: $primary-color;

        &:hover {
          text-decoration: underline;
        }
      }
    }

    .dropdown-toggle::after {
      font-size: 22px;
    }

    .dropdown--isOpen {
      background: #ffffff;
      overflow: auto;
      max-height: unset !important;
      height: auto;
    }

    .dropdown-items {
      position: absolute;
      margin: 1px 15px;
      left: 0;
      right: 0;
      border-radius: 0 0 30px 30px;
      //   border: 1px solid;
      //    border-color: #c0c0c0;
      -webkit-transition: all 0.3s linear, max-height 0.6s ease-out;
      transition: all 0.3s linear, max-height 0.6s ease-out;
      background-color: rgb(224, 227, 228);
      //background-color:#ffffff;
      overflow: visible !important;
    }

    .tips-items {
      background-color: #ffffff;
      padding-top: 15px;
      margin-top: -10px;
    }

    .category-items {
      background-color: rgb(224, 227, 228);
      border-radius: 0 0 30px 30px;
      padding-top: 10px;
    }

    .dropdown-items-category {
      position: absolute;
      margin: 170px 15px 15px 15px;
      left: 0;
      right: 0;
      border-radius: 0 0 30px 30px;
      -webkit-transition: all 0.3s linear, max-height 0.6s ease-out;
      transition: all 0.3s linear, max-height 0.6s ease-out;
      // background-color:lightblue;
      background-color: rgb(224, 227, 228);
      overflow-x: hidden;
    }

    .dropdown-item {
      font-family: Montserrat-Medium;
      font-size: 12px;
      letter-spacing: 0.6px;
      //font-weight: bold;
      color: #333333;
      padding: 7px 26px;
      cursor: pointer;
    }

    .dropdown-items span:hover {
      // background-color: #ffffff;
      background-color: $pggroup-blu;
      color: white;
    }

    .dropdown-items span:active {
      background-color: #ffffff;
      color: $secondary-color;
    }
    .user {
      svg {
        cursor: pointer;
        width: 20px;
        height: 20px;
      }
      &-logged {
        cursor: pointer;
        border: solid 1px #3c3c3b;
        border-radius: 50%;
        font-family: Montserrat-SemiBold;
        font-size: 9px;
        letter-spacing: -0.51px;
        width: 22px;
        height: 22px;
        position: absolute;
        color: #333333;
        display: flex;
        justify-content: center;
        align-items: center;
      }
    }
    .actions {
      display: flex;
      justify-content: flex-end;
      align-items: center;
      svg {
        cursor: pointer;
        width: 22px;
        height: 22px;
      }
      // svg path {
      //     fill: #333333!important;
      // }
    }
  }
}

.count {
  background-color: rgb(180, 179, 179);
  color: white;
  text-align: center;
  margin-left: 10px;
  // padding: 5px;
  height: 20px;
  width: 35px;
  border-radius: 8px;
}

.form-with-all-round-borders {
  display: flex;
  align-items: center;
  margin-top: unset;
  margin: auto;
  // width: 442px;
  width: 900px;
  height: 60px;
  border-radius: 33px;
  border: solid 1px #c0c0c0;
  padding: 0 20px;
}

.form-with-two-round-borders {
  display: flex;
  align-items: center;
  margin-top: unset;
  margin: auto;
  // width: 442px;
  width: 900px;
  height: 60px;
  border-radius: 33px 33px 0px 0px;
  border: solid 1px #c0c0c0;
  padding: 0 20px;

  /*   @media screen and (max-width: "1600px") {
        width: 442px;
    } */
}
.dropdown-menu.show{
    display: flex !important;
    flex-direction: column !important;
}
.boldFont {
  font-weight: bold;
  color: red;
}
[type="search"]::-webkit-search-cancel-button,
[type="search"]::-webkit-search-decoration {
  -webkit-appearance: none;
  appearance: none;
}
