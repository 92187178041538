.signup-choose {
    display: grid;
    grid-template-columns: 1fr 1fr;
    column-gap: 20px;
    @media screen and (max-width: "768px") {
        grid-template-columns: 1fr;
        column-gap: unset;
        row-gap: 20px;
        padding-top: unset;
    }
    align-items: center;
    max-width: 80%;
    margin: auto;
    margin-top: 40px;
    padding-top: 20px;
    padding-bottom: 70px;
    &-card {
        width: 100%;
        padding: 29px;
        border-radius: 19px;
        box-shadow: 0 0 7px 0 #c6c6c6;
        background-color: #ffffff;
        display: flex;
        @media screen and (max-width: "992px") {
            display: unset;
        }
        @media screen and (max-width: "768px") {
            padding: 15px;
        }
        .icon {
            width: 120px;
            height: 120px;
            @media screen and (max-width: "992px") {
                width: 100%;
                height: unset;
                text-align: center;
                margin-bottom: 10px;
            }
        }
        .cta {
            margin-bottom: unset;
        }
        .card-content {
            margin-left: 30px;
            @media screen and (max-width: "992px") {
                margin-left: unset;
                text-align: center;
            }
        }
        .title {
            font-family: Montserrat-Bold;
            font-size: 18px;
            letter-spacing: 0.18px;
            color: #333333;
            margin-bottom: 20px;
        }
        .subtitle {
            font-family: Montserrat-Regular;
            font-size: 14px;
            line-height: 1.57;
            letter-spacing: 0.14px;
            color: #333333;
            margin-bottom: 18px;
        }
    }
}